.Join-US {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}
.Left-J {
  text-transform: uppercase;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
}
.Join-US > hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
}
.Left-J > div {
  display: flex;
  gap: 1rem;
}
.Right-J {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.Email-Countainer {
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}
.Email-Countainer > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}
::placeholder {
  color: var(--lightgray);
}
.Email-Countainer > :nth-child(2) {
  background-color: var(--orange);
  color: white;
}
@media screen and (max-width: 768px) {
  .Join-US {
    flex-direction: column;
    gap: 1rem;
  }
  .Left-J {
    flex-direction: column;
  }
  .Right-J {
    padding: 2rem;
  }
  .Left-J > div {
    flex-direction: column;
    text-align: center;
  }
  .Join-US > hr {
    margin: -20px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 50%;
    left: 25%;
  }

  .Email-Countainer {
    display: flex;
    gap: 0.5rem;
    padding: rem 1rem;
  }
}
