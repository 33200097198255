/* relative values. REM is a relative length value.
REM is useful with regard to font size, and how it can help make webpages responsive.
REM, which stands for Root EM. REM is a value of the value/ data type length. 
Another value of length is our good old friend pixel (px). 
Every property that accepts lengths as a value will accept REM. 
Some of these are margin, padding, and so on. 
*/
.Reasons {
  display: flex;
  padding: 0 2rem;
  gap: 2rem;
}
/*CSS Grid Layout introduces a new unit of length called fr, 
which is short for “fraction”. MDN defines the fr unit as a 
unit which represents a fraction of the available space in the grid container.*/
.Left-R {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}
.Left-R > img {
  object-fit: cover;
}
.Left-R > :nth-child(1) {
  width: 12rem;
  height: 28rem;
  grid-row: 1/3;
}
.Left-R > :nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/4;
}
.Left-R > :nth-child(3) {
  width: 14rem;
  grid-row: 2;
  grid-column: 2/3;
}
.Left-R > :nth-child(4) {
  width: 10rem;
  grid-row: 2;
  grid-column: 3/4;
  height: 11.2rem;
}
.Right-R {
  flex: 1 1;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
.Right-R > span {
  font-weight: bold;
  color: var(--orange);
}
.Right-R > div {
  font-weight: bold;
  color: white;
  font-size: 3rem;
}
.Detailes-R {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Detailes-R > div {
  text-transform: uppercase;
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}
.Detailes-R > div > img {
  width: 2rem;
  height: 2rem;
}
.Detailes-R > div > span {
  margin-top: 7px;
}
#ourpartners {
  color: var(--gray);
  font-weight: normal;
}
.Partners {
  display: flex;
  gap: 1rem;
}
.Partners > img {
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .Reasons {
    flex-direction: column;
  }
  .Left-R {
    display: block;
    overflow: hidden;
  }
  .Left-R > :nth-child(1) {
    gap: 1rem;
    width: 90%;
    margin-left: 5%;
    height: auto;
  }
  .Left-R > :nth-child(2) {
    gap: 1rem;
    width: 90%;
    margin-left: 5%;
  }
  .Left-R > :nth-child(3) {
    gap: 1rem;
    width: 90%;
    margin-left: 5%;
  }
  .Left-R > :nth-child(4) {
    gap: 1rem;
    width: 90%;
    margin-left: 5%;
  }
}
