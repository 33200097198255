.Testimonials {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
}
.Left-T {
  display: flex;
  gap: 2rem;
  flex: 1 1;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
}
.Left-T > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}
.Left-T > :nth-child(2),
.Left-T > :nth-child(3) {
  font-weight: bold;
  font-size: 3rem;
}
.Left-T > :nth-child(4) {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}
.Left-T > :nth-child(5) > :nth-child(1) {
  color: var(--orange);
}
.Right-T {
  flex: 1 1;
  position: relative;
}
.Right-T > img {
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  top: 2rem;
}
.Right-T > :nth-child(1) {
  position: absolute;
  width: 17rem;
  height: 20rem;
  border: 2px solid orange;
  background-color: transparent;
  right: 9rem;
  top: 0.9rem;
}
.Right-T > :nth-child(2) {
  position: absolute;
  width: 17rem;
  height: 19rem;
  background: var(--planCard);
  right: 7rem;
  top: 4rem;
}
.Arrow {
  display: flex;
  position: absolute;
  gap: 1rem;
  bottom: 1rem;
  left: 3rem;
}
.Arrow > img {
  width: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .Testimonials {
    flex-direction: column;
  }
  .Right-T {
    /*
    df
    fdc
    aic
    jcc
    */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .Right-T > div {
    position: relative;
    display: none;
  }
  .Right-T > img {
    top: 0rem;
    right: 0rem;
    position: relative;
    /*asc*/
    align-self: center;
  }
  .Left-T > :nth-child(4) {
    text-transform: none;
    text-align: justify;
    word-spacing: -0.2rem;
    line-height: 30px;
  }
  .Left-T > :nth-child(2),
  .Left-T > :nth-child(3) {
    font-weight: bold;
    font-size: 2rem;
    line-height: 20px;
  }
  .Right-T > :last-child {
    display: block;
    bottom: 0;
    left: 0;
  }
  .Arrow > :nth-child(2) {
    margin-left: 2rem;
  }
  .Arrow > img {
    width: 1rem;
  }
}
