.Footer-Countainer {
  position: relative;
}
.Footer-Countainer > hr {
  border: 1px solid var(--lightgray);
}
.Footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  height: 20rem;
  justify-content: center;
  align-items: center;
}
.Social_links {
  display: flex;
  gap: 4rem;
}
.Social_links > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.Logo-F > img {
  width: 10rem;
}
.Footer-Blur-R {
  bottom: 0rem;
  right: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(300px);
  background-color: red;
}
.Footer-Blur-L {
  bottom: 0rem;
  left: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(300px);
  background-color: rgb(255, 115, 0);
}
