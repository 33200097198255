.Header {
  display: flex;
  justify-content: space-between;
}
.Logo {
  width: 10rem;
  height: auto;
}
.Header-Menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}
.Header-Menu > li:hover {
  cursor: pointer;
  color: var(--orange);
}
.Bars {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.Barss {
  background-color: var(--appColor);
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .Header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .Header-Menu {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}
