.Plan-Countainers {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}
.PH {
  display: flex;
  gap: 3rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: white;
}
.Plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}
.Plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}
.Plan:nth-child(2) {
  background: var(--planCard);
  transform: scale(1.1);
}
.Plan > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}
.Plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}
.Plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}
.Plan > :nth-child(5) {
  font-size: 0.8rem;
}
.Features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.Feature > img {
  width: 1rem;
}
.Plans > :nth-child(2) > svg {
  fill: white;
}
.Plans > :nth-child(2) > button {
  color: orange;
}
.Plan-Blur-L {
  width: 23rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}
.Plan-Blur-R {
  width: 23rem;
  height: 23rem;
  top: 10rem;
  right: 10rem;
}
@media screen and (max-width: 768px) {
  .Plans {
    flex-direction: column;
    gap: 2rem;
  }
  .Plan {
    gap: 1rem;
    padding: 1.5rem;
    width: 14rem;
  }
  .PH {
    flex-direction: column;
    gap: 1rem;
    font-weight: bold;
    font-size: 2rem;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }
  .Plan:nth-child(2) {
    transform: none;
  }
}
